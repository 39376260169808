import React, { useState, useEffect } from 'react'
import css from '@styled-system/css'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import { useSpring, animated as a, to } from 'react-spring'
import get from 'lodash.get'
import { PageTransitionSpring, Ani } from '../../../animation'
import SEO from '../../../components/SEO'
import Layout from '../../../components/Layout'
import {
  Heading,
  Text,
  Container,
  Box,
  Flex,
  Grid,
  Button,
  Select,
  FullWidth,
} from '../../../components/UI'
import Instrument from '../../../components/Instrument/Instrument'
import { Controller, Scene } from 'react-scrollmagic'
import { PageHeader } from '../../../components/PageLayout'

const InstrumentsPageTemplate = React.memo(
  ({
    instrumentType = 'all',
    instrumentTypeSlug,
    data: { contentfulPage: page, allContentfulInstrumentType: types },
  }) => {
    const { allContentfulInstrument } = useStaticQuery(graphql`
      query instruments {
        allContentfulInstrument(sort: { fields: [publishDate], order: DESC }) {
          group(field: type___slug) {
            fieldValue
            edges {
              node {
                ...InstrumentInfo
              }
            }
          }
        }
      }
    `)

    const SeoImg =
      get(page, 'image.childImageSharp.sizes.src') ||
      get(page, 'image.fluid.src')
    const [mount, setMount] = useState(true)
    const [sortSelect, setSortSelect] = useState('date')

    const [instruments, setInstruments] = useState(
      allContentfulInstrument.group
    )
    const [currentInstrument, setCurrentInstrument] = useState(
      instrumentTypeSlug ? instrumentTypeSlug : 'all'
    )
    const [currentInstrumentTitle, setCurrentInstrumentTitle] = useState(
      instrumentType ? instrumentType : 'all'
    )
    useEffect(() => {
      currentInstrument
        ? filterBy(currentInstrument, currentInstrumentTitle)
        : setInstruments(allContentfulInstrument.group)
    }, [])

    const filterBy = (slug, title, replaceUrl = false, sortBy) => {
      !sortBy && setMount(false)
      // sortBy && sort(sortBy)
      sortBy && setSortSelect(sortBy)
      const filtered = allContentfulInstrument.group
        .map(group => {
          return sortFn(group, sortBy)
        })
        .filter(group => {
          // sortBy && sort(sortBy, group)

          return slug !== 'all' ? group.fieldValue === slug : true
        })

      replaceUrl && setCurrentInstrument(slug)
      replaceUrl && setCurrentInstrumentTitle(title)

      !replaceUrl && !sortBy && setInstruments(filtered)

      setTimeout(() => {
        setMount(true)
        if (sortBy || replaceUrl) {
          const el = document.getElementById(`type-filter`)
          setInstruments(filtered)
          el.scrollIntoView({ behavior: 'smooth' })
        }
        if (replaceUrl) {
          window.history.pushState(
            null,
            null,
            slug === 'all' ? `/instruments` : `/instruments/type/${slug}`
          )
        }
      }, 650)
    }

    const sortFn = (group, sortBy) => {
      const g = group
      g.edges.sort(({ node: aNode }, { node: bNode }) => {
        if (!sortBy) return 0
        if (sortBy === 'price') {
          return bNode[sortBy] - aNode[sortBy]
        } else if (sortBy === 'publishDate') {
          const dateA = new Date(aNode[sortBy])
          const dateB = new Date(bNode[sortBy])
          return dateB - dateA
        } else {
          return aNode[sortBy] < bNode[sortBy] ? -1 : 1
        }
      })
      return g
    }

    // const sort = (by, group) => {
    //   console.log(instruments)
    //   setSortSelect(by)

    //   const g = group
    //   g.edges.sort(({ node: aNode }, { node: bNode }) => {
    //     console.log('sort nodes', aNode, bNode)
    //     if (by === 'price') {
    //       return bNode[by] - aNode[by]
    //     } else {
    //       return aNode[by] < bNode[by] ? -1 : 1
    //     }
    //   })
    //   return g
    // }

    return (
      <div>
        <SEO
          title={
            currentInstrumentTitle === 'all'
              ? 'Instruments'
              : `${currentInstrumentTitle}`
          }
          description={page.description}
          image={SeoImg}
          pathname={`${page.slug}/${
            currentInstrument === 'all' ? '' : 'type/'
          }${currentInstrument}`}
        />
        {/*       <Location>
        {({ location }) => {
          const onInstrumentsPageTemplate = location.state && location.state.prevPath && 
            location.pathname.includes('instruments') &&
            location.state.prevPath.includes('instruments')
          return ( */}
        <Layout>
          <PageTransitionSpring animation="fromTop">
            <PageHeader page={page} image={SeoImg} />
          </PageTransitionSpring>
          <Controller>
            <Scene
              triggerElement={`#type-filter`}
              triggerHook={'onLeave'}
              offset={0}
            >
              {progress => (
                <>
                  <Box py={2}>
                    <span id={`type-filter`} />
                    <PageTransitionSpring animation="fade">
                      <TypeFilter
                        filterBy={filterBy}
                        progress={null}
                        animate={false}
                        mount={mount}
                        types={types}
                        sortSelect={sortSelect}
                        currentInstrument={currentInstrument}
                      />
                    </PageTransitionSpring>
                    <TypeFilter
                      animate={true}
                      filterBy={filterBy}
                      progress={progress}
                      mount={mount}
                      types={types}
                      sortSelect={sortSelect}
                      currentInstrument={currentInstrument}
                    />
                  </Box>
                  <Container pb={4}>
                    <PageTransitionSpring
                      // style={{ position: 'relative' }}
                      // disabled={'/instruments/'}
                      // animation="custom"
                      // customAnimation={{
                      //   from: {
                      //     opacity: 0,
                      //   },
                      //   to: {
                      //     opacity: 1,
                      //   },
                      // }}
                      delay={200}
                    >
                      <InstrumentList
                        types={types}
                        instruments={instruments}
                        mount={mount}
                      />
                    </PageTransitionSpring>
                  </Container>
                </>
              )}
            </Scene>
          </Controller>
        </Layout>
        {/*   )
        }}
      </Location> */}
      </div>
    )
  }
)

InstrumentsPageTemplate.propTypes = {}

export default InstrumentsPageTemplate

const TypeFilter = React.memo(
  ({
    sortSelect,
    filterBy,
    types,
    mount,
    currentInstrument,
    progress,
    animate,
    boxProps,
  }) => {
    const sortOptions = [
      { value: 'publishDate', label: 'Date' },
      { value: 'price', label: 'Price' },
      { value: 'title', label: 'Title' },
    ]
    const toggle = progress > 0
    // !! not using theme constants here
    const { translateY, opacity } = useSpring({
      translateY: toggle ? 0 : -75,
      // position: toggle ? 'fixed' : 'static',
      opacity: toggle ? 1 : 0,
      // backgroundColor: toggle ? '#FFE9D0' : 'rgba(255,255,255,0)',
    })

    const spacing = {
      pl: [1, 1, 1, 2],
      pr: [1, 1, 1, 2],
      pt: ['xs', 'xs', 'xs', 1],
      pb: ['xs', 'xs', 'xs', 1],
    }
    return (
      <a.div
        style={
          animate
            ? {
                transform: translateY.to(y => `translate3d(0px, ${y}px, 0)`),
                opacity,
                position: 'fixed',
                zIndex: 2,
                width: '100%',
                top: 75,
              }
            : {}
        }
      >
        <Flex>
          <Box
            p={[1, 1, 1, 1]}
            bg={animate ? 'secondary.1' : 'transparent'}
            overflowY={'auto'}
            {...boxProps}
          >
            <Flex
              w={1}
              css={css({ minWidth: 500 })}
              alignItems="center"
              justifyContent={['flex-start', 'flex-start', 'center']}
              flexWrap="wrap"
              flexDirection="row"
            >
              <Box m={[0, 0, 'xs', 'xs']}>
                <Ani
                  disabled={'all' !== currentInstrument}
                  animation={'custom'}
                  customAnimation={{
                    from: {
                      transform: `translateY(-3px) scale(1.04)`,
                    },
                    to: {
                      transform: `translateY(0px) scale(1)`,
                    },
                  }}
                  mount={mount}
                >
                  <Button
                    onClick={() => filterBy('all', 'all', true)}
                    // as="span"
                    small
                    {...spacing}
                    variant={[
                      'all' === currentInstrument ? 'primary' : 'ghost',
                      'all' === currentInstrument ? 'primary' : 'ghost',
                      'all' === currentInstrument ? 'primary' : 'ghost',
                      'all' === currentInstrument ? 'primary' : 'outline',
                    ]}
                  >
                    All
                  </Button>
                </Ani>
              </Box>
              {types.edges.map(({ node: { title, slug } }) => (
                <Box m={[0, 0, 'xs', 'xs']} key={slug} id="instruments-top">
                  <Ani
                    disabled={slug !== currentInstrument}
                    animation={'custom'}
                    customAnimation={{
                      from: {
                        transform: `translateY(-3px) scale(1.04)`,
                      },
                      to: {
                        transform: `translateY(0px) scale(1)`,
                      },
                    }}
                    mount={mount}
                  >
                    <Button
                      {...spacing}
                      // as="span"
                      onClick={() => filterBy(slug, title, true)}
                      small
                      variant={[
                        slug === currentInstrument ? 'primary' : 'ghost',
                        slug === currentInstrument ? 'primary' : 'ghost',
                        slug === currentInstrument ? 'primary' : 'ghost',
                        slug === currentInstrument ? 'primary' : 'outline',
                      ]}
                    >
                      {title}
                    </Button>
                  </Ani>
                </Box>
              ))}
              {/* <Flex ml={2} flexDirection="row" justify="center" align="center">
              <Text mb={0} mr={1}>
                sort by:
              </Text>
              <Select
                value={sortSelect}
                onChange={e =>
                  filterBy(currentInstrument, false, e.target.value)
                }
              >
                {sortOptions.map(({ value, label }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </Select>
            </Flex> */}
            </Flex>
          </Box>
        </Flex>
      </a.div>
    )
  }
)

const InstrumentList = React.memo(({ instruments, types, mount }) => {
  return (
    <Ani
      // delay={350}
      duration={350}
      animation={'fade'}
      mount={mount}
    >
      <Controller>
        {instruments.map(({ edges, fieldValue }, index) => {
          const type = types.edges.filter(
            ({ node: { title, slug } }) => slug === fieldValue
          )[0].node

          return (
            <Scene
              key={type.slug}
              triggerElement={`#scene--${type.slug}`}
              triggerHook={1}
              // offset={100}
            >
              {progress => (
                <>
                  <Box id={`scene--${type.slug}`} width={1} height={'100%'}>
                    <Ani
                      disabled={index === 0}
                      animation="fromTop"
                      delay={300}
                      mount={progress > 0.1}
                      style={{ height: '100%' }}
                    >
                      <Grid
                        as="ul"
                        cols={{ _: 1, lg: 2 }}
                        gap={{ _: 2, xl: 3 }}
                        mb={{ _: 4, xl: 5 }}
                      >
                        <Box
                          ml={{ _: -2, lg: 0 }}
                          mr={{ _: -2, lg: 0 }}
                          minHeight={[150, 150, 200, 300]}
                          height={'100%'}
                          display="flex"
                          as="li"
                          borderRadius={{ _: 'none', lg: 1 }}
                          style={{
                            backgroundImage: type.image
                              ? `linear-gradient(0deg, rgba(236, 209, 186, 0.5),rgba(0, 0, 0, 0.2)), url(${type.image.fluid.src})`
                              : undefined,
                          }}
                          backgroundSize="cover"
                          backgroundPosition="center"
                          backgroundColor="primary.2"
                          css={css({
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundBlendMode: 'overlay',
                            gridColumn: [1, 1, 1, 1, 'span 2'],
                            // gridRow: 'span 2',
                          })}
                        >
                          <Heading
                            mb={1}
                            mt={1}
                            fontFamily="sans"
                            fontSize={[4, 4, 5, 6]}
                            color={'white'}
                          >
                            {type.title}
                          </Heading>
                        </Box>

                        {edges.map(({ node: instrument }) => (
                          <li key={instrument.id}>
                            <Instrument instrument={instrument} />
                          </li>
                        ))}
                      </Grid>
                    </Ani>
                  </Box>
                </>
              )}
            </Scene>
          )
        })}
      </Controller>
    </Ani>
  )
})
