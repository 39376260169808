import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import InstrumentsPageTemplate from './InstrumentsPageTemplate'

const InstrumentsPage = ({ data, pageContext, location }) => {
  return (
    <InstrumentsPageTemplate
      data={data}
      location={location}
      instrumentType={pageContext.instrumentType}
      instrumentTypeSlug={pageContext.instrumentTypeSlug}
      matchPath={pageContext.matchPath}
    />
  )
}

InstrumentsPage.propTypes = {}

export default InstrumentsPage

export const instrumentsPageQuery = graphql`
         query InstrumentsPageQuery {
           contentfulPage(slug: { eq: "instruments" }) {
             title
             slug
             heading
             subHeading
             image {
               fluid(maxWidth: 1200) {
                 ...GatsbyContentfulFluid
               }
             }
             content {
               content
               json
             }
           }

           allContentfulInstrumentType(
             sort: { fields: [title], order: ASC }
             filter: { instrument: { elemMatch: { id: { ne: null } } } }
           ) {
             edges {
               node {
                 id
                 title
                 slug
                 image {
                   fluid(maxWidth: 1100) {
                     ...GatsbyContentfulFluid
                   }
                 }
               }
             }
           }
         }
       `
